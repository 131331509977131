import { ApplicationAction } from '@wix/platform-editor-sdk';
import { TranslationFunction } from 'i18next';
import { MembersAreaEventType } from '../../types/EditorAppModule';

export const getOpenGeneralSettingsAction = (t: TranslationFunction) => ({
  title: t('Pages_Panel_MemberMenu_Open_GeneralSettings'),
  event: MembersAreaEventType.generalSettings,
  icon: 'settingsAction',
});

const VIEW_SITE_MEMBERS: ApplicationAction = {
  // @ts-expect-error
  type: 'view_site_members',
};

const OPEN_SIGNUP_SETTINGS: ApplicationAction = {
  // @ts-expect-error
  type: 'open_signup_settings',
};

const getDeleteCustomPageAction = (t: TranslationFunction): ApplicationAction => ({
  title: t('Pages_Panel_MemberMenu_Delete_Custom_Page'),
  event: MembersAreaEventType.removePage,
  icon: 'delete_icon',
});

const getAddPageAction = (t: TranslationFunction): ApplicationAction => ({
  title: t('Pages_Panel_MemberMenu_Add_Page'),
  type: 'add_page',
  event: MembersAreaEventType.createBlankPage,
  icon: 'addPagePagesPanel',
});

const getAdvancedURLSettingsAction = (t: TranslationFunction): ApplicationAction => ({
  title: t('Pages_Panel_MemberMenu_URLSettings_Link'),
  event: MembersAreaEventType.renameRouter,
  icon: 'globe',
});

const getDeleteMembersAreaAction = (t: TranslationFunction) => ({
  title: t('Pages_Panel_MemberMenu_Delete_Link'),
  event: MembersAreaEventType.uninstall,
  icon: 'delete_icon',
});

export {
  VIEW_SITE_MEMBERS,
  OPEN_SIGNUP_SETTINGS,
  getDeleteCustomPageAction,
  getAddPageAction,
  getAdvancedURLSettingsAction,
  getDeleteMembersAreaAction,
};
