import { EditorSDK, EventTypeStrings, PageData, PageRef } from '@wix/platform-editor-sdk';
import { createPublicApi } from '../editor-app-module/public-api';

export enum MembersAreaEventType {
  renameRouter = 'renameRouter',
  removePage = 'removePage',
  uninstall = 'uninstall',
  managePages = 'managePages',
  createBlankPage = 'createBlankPage',
  generalSettings = 'generalSettings',
}

export type MembersAreaEventTypeStrings = keyof typeof MembersAreaEventType | EventTypeStrings;

export type MembersAreaOnEventFn = (
  options: { eventType: MembersAreaEventTypeStrings; eventPayload: any },
  editorSDK: EditorSDK,
) => Promise<void>;

export type BiData = { origin?: string };

export type RouterPattern = {
  socialHome: boolean;
  appData: {
    numbers: {};
    appDefinitionId: string;
    appPageId: string;
    menuOrder: number;
    visibleForRoles: string[];
  };
  page: string;
  seoData: {
    title: string;
    description: string;
    keywords: string;
    noIndex: string;
  };
  title: string;
};

export type RouterPatterns = Record<string, RouterPattern>;

export interface MembersPageData extends PageData {
  isPrivate: boolean;
  pageUriSEO: string;
  pageRef?: PageRef;
}

export interface MembersPage {
  pageData: MembersPageData;
  showInIconsMenu: boolean;
  urlOverride?: string | null;
  routerConfig?: {
    appData: {
      appPageId: string;
      appDefinitionId: string;
      visibleForRoles: string[];
      numbers: { key: string; default: number } | undefined;
    };
    socialHome: boolean;
    pageId: string;
  };
  loginMenuTitle?: string;
  showInMemberMenu?: boolean;
  showInLoginMenu?: boolean;
  appDefinitionId: string;
  menuIds: {
    members: string;
    login: string;
    icons: string;
  };
}

export type MembersAreaPublicApi = ReturnType<typeof createPublicApi>;

export enum EventType {
  appMenuReorder = 'appMenuReorder',
  componentDeleted = 'componentDeleted',
  componentGfppClicked = 'componentGfppClicked',
  appActionClicked = 'appActionClicked',
  connectedComponentPasted = 'connectedComponentPasted',
  connectedComponentDuplicated = 'connectedComponentDuplicated',
  concurrentPanelEdit = 'concurrentPanelEdit',
  widgetPasted = 'widgetPasted',
  widgetDuplicated = 'widgetDuplicated',
  controllerAdded = 'controllerAdded',
  controllerSettingsButtonClicked = 'controllerSettingsButtonClicked',
  controllerGfppClicked = 'controllerGfppClicked',
  stateChanged = 'stateChanged',
  stateAdded = 'stateAdded',
  stateDuplicated = 'stateDuplicated',
  pageDeleted = 'pageDeleted',
  siteWasPublished = 'siteWasPublished',
  siteWasFirstSaved = 'siteWasFirstSaved',
  siteWasSaved = 'siteWasSaved',
  startConfiguration = 'startConfiguration',
  welcomeSectionMainActionClicked = 'welcomeSectionMainActionClicked',
  widgetGfppClicked = 'widgetGfppClicked',
  appUpgradeCompleted = 'appUpgradeCompleted',
  instanceChanged = 'instanceChanged',
  componentSelectionChanged = 'componentSelectionChanged',
  globalDesignPresetChanged = 'globalDesignPresetChanged',
  developerModeChanged = 'developerModeChanged',
  developerModeStatusChanged = 'developerModeStatusChanged',
  focusedPageChanged = 'focusedPageChanged',
  componentAddedToStage = 'componentAddedToStage',
  connectedComponentAddedToStage = 'connectedComponentAddedToStage',
  anyComponentAddedToStage = 'anyComponentAddedToStage',
  componentAnimationChanged = 'componentAnimationChanged',
  componentDataChanged = 'componentDataChanged',
  componentDesignChanged = 'componentDesignChanged',
  componentStyleChanged = 'componentStyleChanged',
  switchedFromPreview = 'switchedFromPreview',
  componentArrangementChanged = 'componentArrangementChanged',
  componentDragEnded = 'componentDragEnded',
  componentResizeStarted = 'componentResizeStarted',
  componentRotateEnded = 'componentRotateEnded',
  sitePublishedDialogClosed = 'sitePublishedDialogClosed',
  pageBackgroundChanged = 'pageBackgroundChanged',
  mobileTextScaleChanged = 'mobileTextScaleChanged',
  componentCropSaved = 'componentCropSaved',
  toggleBackToTopButtonOn = 'toggleBackToTopButtonOn',
  componentBehaviorChanged = 'componentBehaviorChanged',
  componentPropsChanged = 'componentPropsChanged',
  switchedToMobileView = 'switchedToMobileView',
  textEditBoxClosed = 'textEditBoxClosed',
  hideMobileElement = 'hideMobileElement',
  showMobileElement = 'showMobileElement',
  pageRenamed = 'pageRenamed',
  addDynamicPageClicked = 'addDynamicPageClicked',
  consentPolicyChanged = 'consentPolicyChanged',
  pageDuplicated = 'pageDuplicated',
  undo = 'undo',
  redo = 'redo',
  addElementsCompClicked = 'addElementsCompClicked',
  addElementsAllCompsClicked = 'addElementsAllCompsClicked',
  addElementsResetClicked = 'addElementsResetClicked',
  appVisitedInDashboard = 'appVisitedInDashboard',
  componentAddedToApp = 'componentAddedToApp',
  documentOperationError = 'documentOperationError',
  solveAddWidgetLimitation = 'solveAddWidgetLimitation',
  componentConnected = 'componentConnected',
  componentDisconnected = 'componentDisconnected',
}
